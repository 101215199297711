import React from "react";
import serviceImage from "../../utils/serviceImg.jpg";
import { FaBrain, FaUserTie, FaFileInvoice, FaBlackTie, FaUsersCog } from "react-icons/fa";
import {
  FaUsersLine,
  FaUserTag,
  FaHandshakeAngle,
  FaUserLock,
  FaBuildingColumns,
  FaCheckToSlot,
  FaChalkboardUser,
  FaGears,
  FaClipboardCheck,
} from "react-icons/fa6";
import { IoIosPaper, IoIosClock } from "react-icons/io";
import Banking from "../../utils/Banking.jpg";
import Technology from "../../utils/technology.jpg";

const Service = () => {
  return (
    <>
      <section className="introduction-section">
        <div className="introduction-content">
          <h2>Discover Our Expert Recruitment Solutions</h2>
          <p>
            At SoliTeck, we are dedicated to providing top-notch recruitment
            solutions for both job seekers and employers. Our range of services
            is designed to make the hiring process efficient, seamless, and
            tailored to your unique needs.
          </p>
        </div>
        <div className="introduction-image">
          <img src={serviceImage} alt="Recruitment Services" />
        </div>
      </section>
      <section className="employer-services">
        <h2>Employer Services</h2>
        <p id="para">
          We understand that finding the right talent is essential for your
          organization's success. Our comprehensive suite of employer services
          is designed to streamline your recruitment process, connect you with
          top-tier candidates, and help you build a winning team. With our
          expertise and innovative tools, we're here to make hiring the best
          talent an effortless and rewarding experience.
        </p>
        <div className="employer-service-container">
          <div className="employer-service">
            <div className="service-content">
              <IoIosPaper className="serviceIcons" />
              <h3>Job Posting</h3>
              <p>
                We provide a user-friendly platform for you to post your job
                openings, complete with a wide range of customization options.
                Our strategic promotion techniques ensure that your job listings
                reach a diverse and qualified pool of candidates.
              </p>
            </div>
          </div>
          <div className="employer-service">
            <div className="service-content">
              <FaUserTag className="serviceIcons" />
              <h3>Candidate Search</h3>
              <p>
                Say goodbye to sifting through stacks of resumes. Our advanced
                candidate matching technology uses AI algorithms to identify the
                most suitable candidates based on your specific job
                requirements, saving you time and effort.
              </p>
            </div>
          </div>
          <div className="employer-service">
            <div className="service-content">
              <FaUsersLine className="serviceIcons" />
              <h3>Screening and Selection Excellence</h3>
              <p>
                Leave the hassle of reviewing resumes and applications to us.
                Our meticulous screening process filters out unqualified
                candidates, presenting you with a curated pool of top-tier
                talent perfectly aligned with your job requirements.
              </p>
            </div>
          </div>
          <div className="employer-service">
            <div className="service-content">
              <IoIosClock className="serviceIcons" />
              <h3>Interview Coordination</h3>
              <p>
                We understand that scheduling interviews can be time-consuming.
                Our dedicated team takes care of interview coordination,
                ensuring that both you and the candidates have a smooth and
                convenient experience.
              </p>
            </div>
          </div>
          <div className="employer-service">
            <div className="service-content">
              <FaHandshakeAngle className="serviceIcons" />
              <h3>Consultative Approach</h3>
              <p>
                We believe in partnership and collaboration. Our expert
                consultants work closely with you to understand your
                organization's culture, values, and objectives. This
                personalized approach enables us to source candidates who not
                only meet the skills criteria but also fit seamlessly into your
                team.
              </p>
            </div>
          </div>
          <div className="employer-service">
            <div className="service-content">
              <FaBlackTie className="serviceIcons" />
              <h3>Employer Branding</h3>
              <p>
                Stand out as an employer of choice with our employer branding
                services. We help you create a compelling company profile that
                showcases your organization's values, culture, and mission,
                attracting top talent who align with your vision.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="candidate-services">
        <h2>Candidate Services</h2>
        <p id="para">
          Unlock your potential and discover your dream career with SoliTeck
          Candidate Services. Our mission is to empower you on your professional
          journey, providing a range of resources and tools to help you navigate
          the job market, enhance your skills, and land your ideal job.
        </p>
        <div className="candidate-service-container">
          <div className="candidate-service">
            <div className="service-content">
              <FaUserLock className="serviceIcons" />
              <h3>Personalized Job Matching</h3>
              <p>
                Say goodbye to endless job listings. Our advanced platform uses
                intelligent algorithms to match your skills, experience, and
                preferences with the perfect job opportunities, saving you time
                and connecting you with the right employers.
              </p>
            </div>
          </div>
          <div className="candidate-service">
            <div className="service-content">
              <FaFileInvoice className="serviceIcons" />
              <h3>Resume Building</h3>
              <p>
                Your resume is your first impression. Let us help you craft a
                standout resume that highlights your strengths and
                accomplishments, making you a top candidate in any application
                process.
              </p>
            </div>
          </div>
          <div className="candidate-service">
            <div className="service-content">
              <FaCheckToSlot className="serviceIcons" />
              <h3>Interview Preparation</h3>
              <p>
                Nail your interviews with our comprehensive interview
                preparation resources. From mock interviews to tips on answering
                tough questions, we're here to boost your confidence and help
                you shine.
              </p>
            </div>
          </div>
          <div className="candidate-service">
            <div className="service-content">
              <FaChalkboardUser className="serviceIcons" />
              <h3>Career Guidance</h3>
              <p>
                Our career experts share valuable insights and advice to help
                you navigate your professional journey. From industry trends to
                job market forecasts, we keep you informed and empowered.
              </p>
            </div>
          </div>
          <div className="candidate-service">
            <div className="service-content">
              <FaUsersCog className="serviceIcons" />
              <h3>Networking Opportunities</h3>
              <p>
                Connect with industry professionals, mentors, and like-minded
                individuals through our networking events and forums. Build
                valuable relationships that can open doors to exciting career
                prospects.
              </p>
            </div>
          </div>
          <div className="candidate-service">
            <div className="service-content">
              <FaClipboardCheck className="serviceIcons" />
              <h3>Career Insights and Advice</h3>
              <p>
                Our career experts share valuable insights and advice to help
                you navigate your professional journey. From industry trends to
                job market forecasts, we keep you informed and empowered.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="industry-expertise">
        <h2>Industry Expertise</h2>
        <p id="main">At SoliTeck, our deep-rooted industry expertise sets us apart as a leader in the world of recruitment. With years of experience and an unwavering commitment to excellence, we have honed our understanding of diverse sectors to provide you with unparalleled insights and solutions.</p>
        <div className="expertise-card">
          <div className="text-center">
            <FaBuildingColumns className="serviceIcons" />
            <h3>Finance and Banking</h3>
            <p>
              We specialize in connecting top finance and banking professionals
              with leading financial institutions. Our in-depth knowledge of the
              industry ensures that we can find the perfect match for your
              financial needs.
            </p>
          </div>
          <img src={Banking} alt="Banking" />
        </div>
        <div className="expertise-card">
          <img src={Technology} alt="Banking" />
          <div className="text-center">
            <FaGears className="serviceIcons" />
            <h3>Technology</h3>
            <p>
              With a focus on the ever-evolving technology sector, we have the
              insights and connections to bring tech talent to your team. From
              software developers to IT managers, we help you find the right
              tech experts.
            </p>
          </div>
        </div>
      </section>
      <section className="technology-tools">
        <h2>Technology and Tools</h2>
        <div className="tool">
          <div className="tool-icon">
            <FaBrain className="brains" />
          </div>
          <div className="tool-content">
            <h3>AI-Driven Matching</h3>
            <p>
              We leverage advanced AI algorithms to match candidates with the
              most suitable job opportunities based on their skills and
              preferences.
            </p>
          </div>
        </div>
        <div className="tool">
          <div className="tool-icon">
            <FaUserTie className="brains" />
          </div>
          <div className="tool-content">
            <h3>Applicant Tracking System</h3>
            <p>
              Our cutting-edge ATS streamlines the recruitment process, ensuring
              efficient management of candidate applications and communications.
            </p>
          </div>
        </div>
      </section>
      {/* <section className="testimonials">
                <h2>Testimonials and Success Stories</h2>
                <div className="testimonial">
                    <blockquote>
                        <p>"[Your Agency Name] helped us find the perfect candidate quickly and efficiently. Their expertise truly made a difference."</p>
                    </blockquote>
                    <cite>- John Smith, CEO of ABC Company</cite>
                </div>
            </section> */}
      <section className="get-started">
        <h2>Get Started</h2>
        <p>Ready to take the next step? Contact us to begin your journey.</p>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
        {/* <a href="/" className="cta-button">Submit Job Opening</a>
                <a href="/" className="cta-button">Register as Job Seeker</a> */}
      </section>
      {/* <section className="contact-information">
                <h2>Contact Information</h2>
                <address>
                    <p>Phone: [Your Phone Number]</p>
                    <p>Email: [Your Email Address]</p>
                    <p>Location: [Your Physical Location, if applicable]</p>
                </address>
            </section> */}
      {/* <section className="faqs">
                <h2>FAQs</h2>
                <div className="faq">
                    <h3>What industries do you specialize in?</h3>
                    <p>We specialize in [Industry 1], [Industry 2], and [Industry 3], leveraging our expertise to connect top talent with exciting opportunities.</p>
                </div>
                <div className="faq">
                    <h3>How does your recruitment process work?</h3>
                    <p>Our process includes [Brief Description of Your Recruitment Process]. We ensure a personalized approach for every client.</p>
                </div>
            </section> */}
    </>
  );
};

export default Service;
