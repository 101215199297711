import { NavLink } from "react-router-dom";
import Logo from '../../utils/Logo.svg';

const Navbar = () => {
  return (
    <>
      <section id="nav">
      <div className="container-fluid bg-white nav_bg">
        <div className="row custom">
          <div className="col-10 mx-3 flex-grow">
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
              <div className="container-fluid">
                <div className="brandImg">
                  <NavLink className="navbar-brand" to="/">
                    <img src={Logo} alt="SoliTeck" className="brandImg img-fluid" />
                  </NavLink>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item px-2">
                      <NavLink className={(navData) => (navData.isActive ? "nav-link menu-active" : 'nav-link')} aria-current="page" to="/">Home</NavLink>
                    </li>
                    <li className="nav-item px-2">
                      <NavLink className={(navData) => (navData.isActive ? "nav-link menu-active" : 'nav-link')} to="/about">About</NavLink>
                    </li>
                    <li className="nav-item px-2">
                      <NavLink className={(navData) => (navData.isActive ? "nav-link menu-active" : 'nav-link')} to="/service">Services</NavLink>
                    </li>
                    <li className="nav-item px-2">
                      <NavLink className={(navData) => (navData.isActive ? "nav-link menu-active" : 'nav-link')} to="/contact">Contact</NavLink>
                    </li>
                  </ul>
                  {/* <form className="d-flex">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">Search</button>
                  </form> */}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      </section>
    </>
  );
};

export default Navbar;