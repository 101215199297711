import { FaFacebookSquare, FaInstagram, FaTwitter, FaLinkedin, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="cust-cont">
                    <div className="sec about_us">
                        <h2>SoliTeck</h2>
                        <p>"Empowering Individuals and Fueling Corporate Growth through Expert Talent Placement and Recruitment Solutions."</p>
                        <ul className='sci'>
                            <li><NavLink to="/"><FaFacebookSquare /></NavLink></li>
                            <li><NavLink to="/"><FaInstagram /></NavLink></li>
                            <li><NavLink to="/"><FaTwitter /></NavLink></li>
                            <li><NavLink to="/"><FaLinkedin /></NavLink></li>
                        </ul>
                    </div>
                    <div className="sec quickLinks">
                        <h2>Quick Links</h2>
                        <ul>
                            <li><NavLink to="/about">About</NavLink></li>
                            <li><NavLink to="/service">Services</NavLink></li>
                            <li><NavLink to="/contact">Privacy Policy</NavLink></li>
                            <li><NavLink to="/contact">Terms & Conditions</NavLink></li>
                            <li><NavLink to="/contact">Contact</NavLink></li>
                        </ul>
                    </div>
                    <div className="sec quickLinks">
                        <h2>We Offer</h2>
                        <ul>
                            <li><NavLink to="/service">Job Posting</NavLink></li>
                            <li><NavLink to="/service">Candidate Search</NavLink></li>
                            <li><NavLink to="/service">Consultative Approach</NavLink></li>
                            <li><NavLink to="/service">Interview Preparation</NavLink></li>
                            <li><NavLink to="/service">Career Guidance</NavLink></li>
                            <li><NavLink to="/service">More...</NavLink></li>
                        </ul>
                    </div>
                    <div className="sec contact">
                        <h2>Contact Us</h2>
                        <ul className="info">
                            <li>
                                <span><FaMapMarkerAlt /></span>
                                <span>101 Plot No. 211 / 212 / 213, Ground Floor, Sector-5, Ghansoli<br />Navi Mumbai
                                    Maharashtra - 400701<br />
                                    India</span>
                            </li>
                            <li>
                                <span><FaEnvelope /></span>
                                <p><a href="mailTo:support@soliteck.com">Email : support@soliteck.com</a></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            <div className="copyright">
                <p>COPYRIGHT © 2023 SoliTeck Recruitment Services | All Rights Reserved</p>
            </div>
        </>
    )
}

export default Footer;