import React from "react";
import { FaPrayingHands } from "react-icons/fa";
import {
  FaUsersGear,
  FaAward,
  FaUserCheck,
  FaHandshake,
  FaHatCowboy,
} from "react-icons/fa6";
import joinUs from "../../utils/Join-Us-PNG-HD.png";

const About = () => {
  return (
    <>
      <section id="introduction" className="welcome-section">
        <FaPrayingHands className="AboutIcon" />
        <div className="welcome-text">Welcome to SoliTeck Recruitment</div>
        <div className="welcome-para">
          As a leading recruitment agency, we specialize in connecting
          exceptional candidates with innovative companies. Our commitment to
          excellence and a personalized approach sets us apart in the
          competitive world of recruitment.
        </div>
        <div className="our-story-card">
          <div className="our-story">
            <FaHatCowboy className="mainIcons" />
            <h2>Our Story</h2>
            <p>
              Founded in 2023, <strong>SoliTeck</strong> emerged with a clear
              vision – to redefine the recruitment landscape. With a passion for
              bringing talent and businesses together, we embarked on a journey
              that has led to countless successful partnerships and thriving
              careers.
            </p>
          </div>
        </div>
      </section>

      <section className="why-choose-us">
        <h2>Why Choose Us</h2>
        <p id="para">
          At SoliTeck, our mission is to empower both job seekers and employers
          by facilitating meaningful connections. We stand out as your dedicated
          partner in your quest for the perfect career. With a strong commitment
          to your success, we offer a unique blend of expertise, technology, and
          support that sets us apart.
        </p>
        <div className="items">
          <div className="why-choose">
            <FaAward className="aboutIcons" />
            <h3>Expertise</h3>
            <p>
              With a team of industry specialists, we bring in-depth knowledge
              and insights to every recruitment process.
            </p>
          </div>
          <div className="why-choose">
            <FaHandshake className="aboutIcons" />
            <h3>Personalized Approach</h3>
            <p>
              We understand that every candidate and company is unique. Our
              tailored solutions ensure the perfect fit for both sides.
            </p>
          </div>

          <div className="why-choose">
            <FaUsersGear className="aboutIcons" />
            <h3>Network</h3>
            <p>
              Our extensive network gives us access to top talent and exciting
              opportunities across various indutsries.
            </p>
          </div>

          <div className="why-choose">
            <FaUserCheck className="aboutIcons" />
            <h3>Results-Driven</h3>
            <p>
              We measure our success by the success stories of our candidates
              and clients. Your goals are our goals.
            </p>
          </div>
        </div>
      </section>

      {/* <section className="meet-the-team">
                <h2>Meet the Leaders</h2>
                <div className="team-card">
                    <img className="team-image" src="team_member_1.jpg" alt="Team Member 1" />
                    <div className="team-details">
                        <h3 className="team-name">John Doe</h3>
                        <p className="team-title">Recruitment Specialist</p>
                    </div>
                </div>
                <div className="team-card">
                    <img className="team-image" src="team_member_2.jpg" alt="Team Member 2" />
                    <div className="team-details">
                        <h3 className="team-name">Jane Smith</h3>
                        <p className="team-title">Career Advisor</p>
                    </div>
                </div>
            </section> */}

      <section className="join-us-card">
        <div className="join-us-text">
          <h2>Join Our Team</h2>
          <p>
            Whether you're seeking your next career move or looking to build a
            dynamic team, we invite you to partner with SoliTeck...
          </p>
          <a href="/contact" className="join-button">
            Join Us
          </a>
        </div>
        <div className="join-us-image">
          <img src={joinUs} alt="Join Us" />
        </div>
      </section>
    </>
  );
};

export default About;
