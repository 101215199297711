import React from "react";
import { useState } from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt, FaSuitcase } from 'react-icons/fa';

const Contact = () => {
    const [data, setData] = useState({
        fullname: '',
        number: '',
        email: '',
        description: ''
    });
    const InputEvent = (event) => {
        const { name, value } = event.target;
        setData((preVal) => {
            return {
                ...preVal,
                [name]: value,
            }
        })
    };
    const formSubmit = (e) => {
        e.preventDefault();
        alert(`${data.fullname}`);
    };
    return (
        <>
            <section className="contactSec">
                <div id="contact" className="contact-area section-padding">
                    <div className="container">
                        <div className="section-title text-center contactHeading">
                            <h1>Get in Touch</h1>
                            <p>We're here to help you every step of the way. Whether you have questions about our services, want to discuss potential opportunities, or simply want to connect, our dedicated team is ready to assist you. Reach out to us today and let's start a conversation.</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="contact">
                                    <form onSubmit={formSubmit}>
                                        <div className="row">
                                                <div className="form-group col-md-6 my-1">
                                                    <input type="text" name="fullname" value={data.fullname} onChange={InputEvent} className="form-control" id="name" placeholder="Full name" />
                                                </div>
                                                <div className="form-group col-md-6 my-1">
                                                    <input type="email" name="email" value={data.email} onChange={InputEvent} className="form-control" id="email" placeholder="name@example.com" />
                                                </div>
                                                <div className="form-group col-md-12 my-1">
                                                    <input type="number" name="number" value={data.number} onChange={InputEvent} className="form-control" id="number" placeholder="Mobile Number" />
                                                </div>
                                                <div className="form-group col-md-12 my-1">
                                                    <textarea rows="6" className="form-control" name="description" value={data.description} onChange={InputEvent} placeholder="Your Message" id="description"></textarea>
                                                </div>
                                            <div className="col-md-12 text-center my-1">
                                                <button type="submit" value="Send message" name="submit" id="submitButton" className="btn btn-contact-bg" title="Submit Your Message!">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="single_address">
                                    <i><FaMapMarkerAlt /></i>
                                    <h4>Our Address</h4>
                                    <p>101 Plot No. 211 / 212 / 213, Ground Floor, Sector-5, Ghansoli
                                        Navi Mumbai Maharashtra - 400701
                                        India</p>
                                </div>
                                <div className="single_address">
                                    <i><FaEnvelope /></i>
                                    <h4>Send your message</h4>
                                    <p>support@soliteck.com</p>
                                </div>
                                <div className="single_address">
                                    <i><FaPhoneAlt /></i>
                                    <h4>Call us on</h4>
                                    <p>(+91) 9321234042</p>
                                </div>
                                <div className="single_address">
                                    <i><FaSuitcase /></i>
                                    <h4>Work Time</h4>
                                    <p>Mon - Fri: 08.00 - 16.00. <br />Sat: 10.00 - 14.00</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;