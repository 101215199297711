import React from "react";
import HomeImage from "../../utils/Image1.svg";
import AboutImage from "../../utils/upscaled.png";
import { FaStreetView, FaAddressCard } from "react-icons/fa";
import { FaFileShield } from "react-icons/fa6";
import Contact from "./Contact";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <>
      <section id="header" className="d-flex align-items-center">
        <div className="container-fluid custom-Bac">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-6 pt-custom pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                  <h1>
                    Connecting Ambition <br /> with Opportunity
                  </h1>
                  <div className="seperator">
                    <span className="element"></span>
                  </div>
                  <h2 className="my-3">Your Journey to Success Starts Here.</h2>
                  <div className="mt-3">
                    <NavLink to="/service" className="btn-get-started">
                      Get Started
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 header-img">
                  <img
                    src={HomeImage}
                    alt="Home Img"
                    className="img-fluid animated"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Wave">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path className="shape-fill"
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            ></path>
          </svg>
        </div>
      </section>

      <section className="about-sec">
        <section className="aboutSec">
          <div className="heading">
            <h1>About Us</h1>
          </div>
          <div className="AboutContainer">
            <section className="about">
              <div className="aboutImage">
                <img src={AboutImage} alt="Soliteck" />
              </div>
              <div className="aboutContent">
                <p className="mainPara">
                  Cultivating Success Stories: Pioneering Excellence in
                  Recruitment and Talent Engagement at SoliTeck
                </p>
                <h2>Recruitment</h2>
                <p>
                  At SoliTeck Recruitment, we stand as a beacon of opportunity,
                  dedicated to transforming aspirations into accomplishments.
                  With a profound understanding of the dynamic interplay between
                  talent and success, we've carved our niche as a premier
                  recruitment partner. Our journey is rooted in a commitment to
                  bridge the gap between exceptional candidates and
                  forward-thinking organizations, fostering growth and
                  innovation.
                </p>
                <NavLink to="/about" className="read-more">
                  Read More
                </NavLink>
              </div>
            </section>
          </div>
        </section>
      </section>
      <section className="serviceSec">
        <div className="container">
          <div id="service-section">
            <h2>Our Services</h2>
            <p id="paragraph">
              We specialize in bridging the gap between exceptional talent and
              forward-thinking companies. Our dedicated team is here to provide
              a seamless and tailored experience, ensuring that your hiring
              journey is efficient, effective, and results-driven.
            </p>
            <div className="cards">
              <div className="card">
                <NavLink to="/service" className="service">
                  <FaStreetView className="icons" />
                  <div className="content">
                    <h3>Sourcing</h3>
                    <p>
                      We help companies find professionals that possess a
                      similar ambition and growth strategy through precise
                      candidate-mapping.
                    </p>
                  </div>
                </NavLink>
              </div>
              <div className="card">
                <NavLink to="/service" className="service">
                  <FaFileShield className="icons" />
                  <div className="content">
                    <h3>Process Management</h3>
                    <p>
                      After carefully understanding the company’s needs, we
                      develop and execute the recruitment process that connects
                      candidates with their ideal firm.
                    </p>
                  </div>
                </NavLink>
              </div>
              <div className="card">
                <NavLink to="/service" className="service">
                  <FaAddressCard className="icons" />
                  <div className="content">
                    <h3>Candidate Management</h3>
                    <p>
                      With a track record of 83% onboard ratio, ethics stand
                      above all else. This includes a follow-up with the
                      candidates about their future and how they plan to move
                      forward.
                    </p>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contactSec">
        <Contact />
      </section>
    </>
  );
};

export default Home;
